<template>
  <div class="view view--light">
    <header>
      <p class="text-h5">{{ $t('text.Greetings') }} {{ `${user.first_name} ${user.last_name}` }}</p>
    </header>

    <v-row class="mt-2" v-if="!customerAircraft">
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <v-row class="mt-2" v-else>
      <v-col :md="6" :cols="12">
        <v-card>
          <v-card-title class="flex flex-column align-start">
            <span>{{ $t('headline.totalOverview') }}</span>
            <span class="text-body-2 font-weight-regular grey--text">&nbsp;</span>
          </v-card-title>
          <v-card-text>
            <div class="flex justify-space-around mt-4 mb-2" style="flex-wrap: wrap;">
              <span class="mb-2" style="min-width: 8rem">
                <v-icon class="mr-2">mdi-airplane-clock</v-icon>
                <span class="text-h5">{{ latestFlightlogEntry.time_since_new || '-' }} h</span>
              </span>
              <span class="mb-2" style="min-width: 8rem">
                <v-icon class="mr-2">mdi-airplane-landing</v-icon>
                <span class="text-h5">{{ latestFlightlogEntry.landings_since_new || '-' }} {{ $t('label.landings') }}</span>
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :md="6" :cols="12">
        <v-card :to="{ name: BOARDBOOK }" link title="Zum letzten Flug" class="kpi--link">
          <v-card-title class="flex flex-column align-start">
            <span>{{ $t('headline.lastFlight') }}</span>
            <span class="text-body-2 font-weight-regular grey--text">
              <span v-if="latestFlightlogEntry.to_utc">{{ $d(new Date(latestFlightlogEntry.to_utc), 'long') }}</span>
              <span v-else>&nbsp;</span>
            </span>
          </v-card-title>
          <v-card-text>
            <div class="flex justify-space-around mt-4 mb-2" style="flex-wrap: wrap;">
              <span class="mb-2" style="min-width: 8rem">
                <v-icon class="mr-2">mdi-airplane-clock</v-icon>
                <span class="text-h5">{{ latestFlightlogEntry.flight_time || '-' }} h</span>
              </span>
              <span class="mb-2" style="min-width: 8rem">
                <v-icon class="mr-2">mdi-airplane-landing</v-icon>
                <span class="text-h5">{{ latestFlightlogEntry.cnt_ldg || '-' }} {{ $t('label.landings') }}</span>
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col :md="8" :cols="12">
        <v-card>
          <v-card-title>Status</v-card-title>
          <v-card-text>
            <div class="kpi-container">
              <div class="kpi">
                <h3 class="kpi__headline text-h5">Zelle</h3>
                <div class="kpi__symbol">
                  <v-avatar color="success" size="56">
                    <v-icon color="white">mdi-thumb-up-outline</v-icon>
                  </v-avatar>
                </div>
              </div>

              <div class="kpi">
                <h3 class="kpi__headline text-h5">Motor</h3>
                <div class="kpi__symbol">
                  <v-avatar color="warning" size="56">
                    <v-icon color="white" style="transform: rotate(60deg)">mdi-thumb-up-outline</v-icon>
                  </v-avatar>
                </div>
                <div class="kpi__description">Vergaser Kontrolle in 9 Stunden fällig.</div>
              </div>

              <div class="kpi">
                <h3 class="kpi__headline text-h5">Propeller</h3>
                <div class="kpi__symbol">
                  <v-avatar color="error" size="56">
                    <v-icon color="white">mdi-thumb-down-outline</v-icon>
                  </v-avatar>
                </div>
                <div class="kpi__description">Eine AD nicht bearbeitet. Per Email informiert unter</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_LOGS_MODULE } from '@/store/modules/customerAircraftFlightlogs';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import { BOARDBOOK } from '@/router/route-types';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';

export default {
  name: 'view-dashboard',

  components: {
    AlertNoAircraft
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`,
      latestFlightlogEntry: `${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/latest`,
      isLoading: `${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/isLoading`,
      user: `${USER_MODULE}/currentUser`
    }),

    auth() {
      return this.$store.state.auth;
    },

    BOARDBOOK() {
      return BOARDBOOK;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      if (this.customerAircraft?.id) {
        await this.$store.dispatch(`${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/GET_FLIGHTLOGS`, {
          filter: {
            customer_aircraft: {
              _eq: this.customerAircraft.id
            }
          }
        });
      }
    }
  },

  watch: {
    'customerAircraft.id'() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.kpi {
  > * {
    text-align: center;
    width: 100%;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 1rem;
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    align-items: flex-start;
    margin-bottom: 1rem;
    min-width: 13rem;
    width: calc(100% / 3);
  }

  @media screen and (min-width: $screen-md) {
    margin-bottom: 0rem;
  }

  &__symbol {
    margin-top: 1rem;
  }

  &__description {
    margin-top: 1rem;
  }

  &--link {
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: rgba(var(--c-brand-rgb), 0.05);
    }
  }
}
</style>
